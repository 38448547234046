<script>
import TestHeader from './headers/TestHeader.vue';
import SenHeader from './headers/SenHeader.vue';
import CPRHeader from './headers/CprHeader.vue';
import MojHeader from './headers/MojHeader.vue';

export default {
  name: 'commonHeader',
  props: {
    window: Object
  },
  computed: {
    headerComponent() {
      if (this.$route.meta.requiresSenHeader) {
        return SenHeader;
      } else if (this.$route.meta.requiresCPHeader) {
        return CPRHeader;
      } else if (this.$route.meta.requiresTestHeader) {
        return TestHeader;
      } else if (this.$route.meta.requiresMojHeader) {
        return MojHeader;
      }
      return null
    }
  }
}
</script>

<template>
  <div class="header">
    <component :is="headerComponent" />
  </div>
</template>